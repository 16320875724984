<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
// import ImageFile from '@/components/buttons/image-file';
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import NewListingTable from "@/components/listings/new-listing-table";
import basicTable from "@/components/listings/basic-table";
// import baseTable from "@/components/listings/base-table";
import InputModal from "@/components/modals/input-modal";
import InputGroup from "@/components/forms/input-group";
/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        NewListingTable,
        ConfirmationModal,
        MessageModal,
        basicTable,
        InputModal,
        InputGroup,
        // baseTable
        // ImageFile
    },
    data() {
        const id = this.$route.params.id;
        const modeName = 'view';
        return {
            id: id,
            title: `Agency view`,
            modeName,
            items: [
                {
                    text: "Agency"
                },
                {
                    text: 'view',
                    active: true,
                },
            ],
            tableData: {
                agency_name: {
                    label: "Agency Name",
                    value: "",
                },
            },

            details_fields: [
                { key: "agency_name", "label": "Agency Name" },
                { key: "is_verified", "label": "Is Verified" },
            ],
            details_data: {},

            agency_fields: [
                { key: "id", "label": "ID" },
                { key: "amount", "label": "Amount" },
                { key: "remark", "label": "Remark" },
                { key: "ref", "label": "Created By" },
                { key: "created_at", "label": "Created Date" },
            ],

            agent_fields: [
                // { key: "id", "label": "ID" },
                { key: "member.full_name", "label": "Agent Name" },
                { key: "registration_number", "label": "Registration Number" },
                { key: "orders_count", "label": "Total Agreements" },
                { key: "payout_amount", "label": "Points Balance" },
            ],

            //agency payout data
            agency_payout_items: [],
            agency_payout_page: 1,
            agency_payout_per_page: 20,
            agency_payout_loading: true,
            agency_payout_total: 0,
            total_payout: 0,

            agent_filter: {
                agency_id: id
            },

            payout_data: {
                "agency_id": id, 
                "remark": "",
                "amount": 0
            },
            payout_options: [
                { item: true, name: 'Add' },
                { item: false, name: 'Deduct' }
            ],
            payout_selected:true,
            errors: {},
            componentKey: 0,
            has_permission: true
        };
    },
    computed: {

    },
    async mounted() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/agency/${this.id}`, {
            headers: authHeader(),
        });
        if (result && result.data['code'] === 'success') {
            const data = result.data['data'];
            
            for (const key of Object.entries(this.tableData)) {
                if (data[key[0]]) {
                    this.tableData[key[0]].value = data[key[0]];
                } 
            }
            this.details_data = data;
        } else if (result && result.data['code'] === 'unknown'){
            this.$refs.messageModal.showModal('Data Not found')
        }
        await this.loadPayoutHistory(1);
    },
    methods: {
        async loadPayoutHistory(page) {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/agency-payout/${this.id}`, {
                headers: authHeader(),
                params: {
                    "current_page": page,
                    "length": this.agency_payout_per_page
                }
            });
            if (result && result.data['code'] === 'success') {
                const { data } = result.data; 
                let { data: table_data } = data.payout_history;
                table_data.map((e) => {
                    if (e.add_by == null) {
                        e.add_by = '-';
                    }

                    if (parseInt(e.amount) < 0) {
                        e._rowVariant = 'danger'
                    }
                    
                    return e;
                });
                this.agency_payout_items = table_data;
                this.agency_payout_loading = false;
                this.agency_payout_total = data.payout_history.total;
                this.total_payout = data.available_points;
            } else if  (result.data.code === "permission_denied") {
                this.has_permission = false; 
                this.agency_payout_loading = false;
                this.$refs.messageModal.showModal("permission Denied");
            }
        },
        async changePage(e) {
            await this.loadPayoutHistory(e);
        },
        async addPayout() {
            this.errors = {};
            this.payout_data.amount = this.payout_selected ? Math.abs(this.payout_data.amount) : -Math.abs(this.payout_data.amount);
            const result = await axios.post(`${process.env.VUE_APP_APIURL}/agency-add-payout`, this.payout_data, {
                headers: authHeader(),
            });
            if (result.data.code === "invalid_field") {
                this.errors = result.data.errors;
            } else if (result.data.code === "success") {
                this.$refs.InputModal.closeModal();
                this.agency_payout_page = 1;
                await this.loadPayoutHistory(this.agency_payout_page);
                this.componentKey += 1;
            } 
            this.agency_payout_page = 1;
        },
        openModal() {
            this.errors = {};
            this.payout_data.amount = 0;
            this.payout_data.remark = '';
            this.payout_selected = true;
            this.$refs.InputModal.showModal();
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <InputModal title="Edit Payout" ref="InputModal">
            <template #inputField>
                <!-- <div class="row"> -->
                    <div class="col-12 mb-2">
                        <span class="subTitle-font">Current Payout Amount:</span> {{ total_payout }}
                    </div>
                    
                    <div class="col-12">
                        <InputGroup id="amount" name="amount" type="number" v-model.trim="payout_data.amount" displayName="Amount" :error="errors.amount"/>
                    </div>
                    <div class="col-12">
                        <b-form-radio-group v-model="payout_selected" :options="payout_options" class="mb-3" value-field="item" text-field="name">
                            
                        </b-form-radio-group>
                    </div>

                    <div class="col-12">
                        <InputGroup id="remark" name="remark" type="text" v-model.trim="payout_data.remark" displayName="Remark" :error="errors.remark"/>
                    </div>

                    <div class="col-12">
                        <button class="btn btn-orange" @click="addPayout">
                            Confirm
                        </button>
                    </div>
                <!-- </div> -->
            </template>
        </InputModal>
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-orange mb-4" @click="openModal">
                                    <i class="mdi mdi-plus mr-2"></i> Edit Payout
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p style="font-size:16px; font-weight: 900;" class="mb-0">
                                    Agency Name: {{ details_data.agency_name }}
                                </p>
                            </div>

                            <div class="col-12 col-md-6">
                                <p style="font-size:16px; font-weight: 900;" class="mb-2">Current Payout Amount: RM {{ total_payout }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p style="font-size:16px; font-weight: 900;" class="mb-0">
                                    Verification Status: <b-badge class="font-size-13" :variant="parseInt(details_data.is_verified) == 1 ? 'success' : 'danger' " v-if="has_permission">{{ parseInt(details_data.is_verified) == 1 ? 'Verified' : "Not Verified" }}</b-badge>
                                </p>
                            </div>

                            <div class="col-12 col-md-6">
                                <p style="font-size:16px; font-weight: 900;" class="mb-0">
                                    Total Agents Registered: {{ details_data.agent_count }}
                                </p>
                            </div>

                            <div class="col-12 mt-3">
                                <basicTable :items="agency_payout_items" :fields="agency_fields" :perPage="agency_payout_per_page" @page-click="changePage" :isBusy="agency_payout_loading" :totalRows="agency_payout_total" :key="componentKey">
                                </basicTable>
                            </div>

                            <div class="col-12 mt-3">
                                <NewListingTable :entries="parseInt(10)" :filter="agent_filter" :fields="agent_fields" path="agent" ref="list_table">
                                    <template v-slot:cell(member.full_name)="row">
                                        <router-link class="mr-3 text-primary" :to="{name: 'Agent View', params: {id: row.item.id}}">
                                            {{ row.value }}
                                        </router-link>
                                    </template>
                                </NewListingTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>